import React, { Component } from 'react';
import Add from './svg/add';
import ClipPath from './svg/clipPath';
import Defs from './svg/defs';
import Flip from './svg/flip';
import Reverse from './svg/reverse';
import { ReactComponent as Rotate } from '../images/rotate.svg';
import Special from './svg/special';
import Standard from './svg/standard';
import Subtract from './svg/subtract';

class Board extends Component {
	state = {
		confirmed: false,
		confirmForfeit: false,
		drag: {},
		pendingAction: false,
		turnChange: null
	};

	constructor(props) {
		super(props);

		this.drag = React.createRef();
	};

	componentDidMount() {
		window.addEventListener('mousemove', this.handleMouseMove, false);
		window.addEventListener('mouseup', this.handleMouseUp, false);
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.state.pendingAction) {
			this.setState({ pendingAction: false });
		}

		if (prevProps.game.phase !== this.props.game.phase) {
			this.setState({ turnChange: this.props.game.phase });
		} else if (prevProps.game.phase === this.props.game.phase && this.turnChange) {
			this.setState({ turnChange: null });
		} 

		if (prevProps.game.phase === 'interim' && this.props.game.phase !== 'interim') {
			this.setState({ confirmed: false });
		}

		if (prevProps.game.status === 'active' && this.props.game.status === 'complete') {
			this.setState({ confirmForfeit: false });
		}
	}

	getDeckCoordinates(key) {
		return {x: key % 3 * 93, y: Math.floor(key / 3) * 119};
	};

	getHandCoordinates(key) {
		return {x: key * 93, y: 0};
	};

	getHandActionCoordinates(key) {
		return {transform: `translate(${key * 93}, 114)`};
	};

	getSetProperties(side, setNumber) {
		if (this.props.game.sets[side] >= setNumber) {
			return {fill: 'url(#set-gradient)', filter: 'url(#glow-score)'};
		}

		return {fill: '#181818'};
	};

	handleAction(action, data = {}) {
		if (this.state.pendingAction) {
			return;
		}

		const game = this.props.game;
		const activeTurn = ((game.phase === 'p1' && game.p1_id === game.user_id) || (game.phase === 'p2' && game.p2_id === game.user_id));

		if (! activeTurn && game.phase !== 'interim') {
			return;
		}

		const updates = {pendingAction: true};

		if (action === 'continue') {
			updates.confirmed = true;
		}

		this.setState(updates);

		this.props.onAction(action, data);
	};

	handleMouseDown = (event, card) => {
		if (! card.id || ! this.drag.current) {
			return;
		}

		const svg = event.target.closest('svg').getBoundingClientRect();

		card.offsetX = event.clientX - svg.left;
		card.offsetY = event.clientY - svg.top;

		this.setDragTransform(event, card);
		this.setState({ drag: card });
	};

	handleMouseMove = event => {
		const card = this.state.drag;

		if (! card.id || ! this.drag.current) {
			return;
		}

		this.setDragTransform(event, card);
		this.determineDeckTarget(event);
	};

	handleMouseUp = event => {
		const card = this.state.drag;

		if (! card.id) {
			return;
		}

		if (this.determineDeckTarget(event)) {
			document.querySelector('#player-deck').classList.remove('target');

			this.props.onAction('play-card', {id: card.id});

			this.setState({ drag: {}, pendingAction: true });
		} else {
			this.setState({ drag: {} });
		}
	};

	confirmForfeit = value => {
		if (this.props.game.status === 'complete') {
			return;
		}

		this.setState({ confirmForfeit: value });
	};

	setDragTransform(event, card) {
		const game = document.querySelector('#game-container').getBoundingClientRect();

		const x = event.clientX - card.offsetX - game.left;
		const y = event.clientY - card.offsetY - game.top;

		this.drag.current.style.left = `${x}px`;
		this.drag.current.style.top = `${y}px`;
	};

	determineDeckTarget(event) {
		const deck = document.querySelector('#player-deck');
		const bcr = deck.getBoundingClientRect();

		if (event.clientX >= bcr.x && event.clientX <= bcr.x + bcr.width && event.clientY >= bcr.y && event.clientY <= bcr.y + bcr.height) {
			deck.classList.add('target');

			return true;
		} else {
			deck.classList.remove('target');
		}
	};

	render() {
		const { game } = this.props;
		const { confirmed, confirmForfeit, drag, pendingAction, turnChange } = this.state;
		const activeTurn = ((game.phase === 'p1' && game.p1_id === game.user_id) || (game.phase === 'p2' && game.p2_id === game.user_id));
		const canPlayCard = activeTurn && ((game.p1_id === game.user_id && game.p1_card === 0) || (game.p2_id === game.user_id && game.p2_card === 0));
		const currentHandTypes = game.hand.you.reduce((cards, card) => {
			if (card.type === 'special' && card.card_id === 29) {
				cards.flip = true;
			} else if (card.type === 'special' && card.card_id === 33) {
				cards.flip = true;
				cards.change = true;
			} else if (card.type === 'flip') {
				cards.flip = true;
			}

			return cards;
		}, {});

		return (
			<React.Fragment>
				<svg id="board-container" viewBox="0 0 890 794" className={drag.id ? 'drag' : 'game'}>
					<Defs />
					<ClipPath />
					<g filter="url(#inner-shadow-board-top)" mask="url(#score-mask)">
						<rect x="276" y="70" width="75" height="30" fill="#181818" />
						<text className="score" x="313.5" y="84.5">{game.total.you}</text>
						<rect x="539" y="70" width="75" height="30" fill="#181818" />
						<text className="score" x="576.5" y="84.5">{game.total.opponent}</text>
						<rect {...this.getSetProperties('you', 1)} x="276" y="115" width="75" height="15" />
						{game.sets.you >= 1 && <rect className="fade" x="276" y="115" width="75" height="15" />}
						<rect {...this.getSetProperties('you', 2)} x="196" y="115" width="75" height="15" />
						{game.sets.you >= 2 && <rect className="fade" x="196" y="115" width="75" height="15" />}
						<rect {...this.getSetProperties('you', 3)} x="116" y="115" width="75" height="15" />
						{game.sets.you >= 3 && <rect className="fade" x="116" y="115" width="75" height="15" />}
						<rect {...this.getSetProperties('opponent', 1)} x="539" y="115" width="75" height="15"/>
						{game.sets.opponent >= 1 && <rect className="fade" x="539" y="115" width="75" height="15" />}
						<rect {...this.getSetProperties('opponent', 2)} x="619" y="115" width="75" height="15" />
						{game.sets.opponent >= 2 && <rect className="fade" x="619" y="115" width="75" height="15" />}
						<rect {...this.getSetProperties('opponent', 3)} x="699" y="115" width="75" height="15" />
						{game.sets.opponent >= 3 && <rect className="fade" x="699" y="115" width="75" height="15" />}
					</g>
					<g mask="url(#name-mask)">
						<rect x="33.5" y="70" width="236.5" height="30" fill="#181818" filter="url(#inner-shadow-board-top)" />
						<text className="name" x="53.5" y="84.5" clipPath="url(#left-name)">{game.users.you}</text>
						<rect x="620" y="70" width="236.5" height="30" fill="#181818" filter="url(#inner-shadow-board-top)" />
						<text className="name right" x="836.5" y="84.5" clipPath="url(#right-name)">{game.users.opponent}</text>
					</g>
					<g mask="url(#turn-mask)">
						<path className="player-turn" d="M 445 20 v 160 a 80, 80 0 0 1 0, -160" fill={activeTurn ? 'url(#turn-gradient-left)' : '#181818'} filter={activeTurn ? 'url(#glow)' : undefined} />
						{((turnChange === 'p1' && game.p1_id === game.user_id) || (turnChange === 'p2' && game.p2_id === game.user_id)) && <path className="player-turn fade" d="M 445 20 v 160 a 80, 80 0 0 1 0, -160" fill="#181818" />}
						<path className="player-turn" d="M 445 20 v 160 a 80, 80 0 1 0 0, -160" fill={activeTurn || game.phase === 'interim' ? '#181818' : 'url(#turn-gradient-right)'} filter={activeTurn || game.phase === 'interim' ? undefined : 'url(#glow)'} />
						{((turnChange === 'p1' && game.p2_id === game.user_id) || (turnChange === 'p2' && game.p1_id === game.user_id)) && <path className="player-turn fade" d="M 445 20 v 160 a 80, 80 0 1 0 0, -160" fill="#181818" />}
					</g>
					{game.phase_time >= 0 && game.phase_time <= 10 && (game.phase === 'p1' || game.p2_id !== -1) && <text className="game-timer-text" x="445" y="100">{game.phase_time}</text>}
					<g id="player-deck" transform="translate(80, 180)" filter="url(#inner-shadow-board-bottom)">
					{[...Array(9).keys()].map((c, k) => (
						<React.Fragment key={k}>
							<rect {...this.getDeckCoordinates(k)} width="83" height="109" rx="5" fill="#181818" />
							{!!game.cards.you[k] && <React.Fragment>
								{game.cards.you[k].type === 'standard' && <Standard {...this.getDeckCoordinates(k)} value={game.cards.you[k].value} />}
								{game.cards.you[k].type === 'add' && <Add {...this.getDeckCoordinates(k)} value={game.cards.you[k].value} />}
								{game.cards.you[k].type === 'subtract' && <Subtract {...this.getDeckCoordinates(k)} value={game.cards.you[k].value} />}
								{game.cards.you[k].type === 'flip' && <Flip {...this.getDeckCoordinates(k)} value={game.cards.you[k].value} />}
								{game.cards.you[k].type === 'special' && <Special {...this.getDeckCoordinates(k)} value={game.cards.you[k].value} card={game.cards.you[k]} />}
							</React.Fragment>}
							{!!((game.p1_stand && game.p1_id === game.user_id) || (game.p2_stand && game.p2_id === game.user_id)) && <rect {...this.getDeckCoordinates(k)} width="83" height="109" rx="5" fill="#181818" fillOpacity="0.5" />}
						</React.Fragment>
					))}
					</g>
					<g transform="translate(541, 180)" filter="url(#inner-shadow-board-bottom)">
					{[...Array(9).keys()].map((c, k) => (
						<React.Fragment key={k}>
							<rect {...this.getDeckCoordinates(k)} width="83" height="109" rx="5" fill="#181818" />
							{!!game.cards.opponent[k] && <React.Fragment>
								{game.cards.opponent[k].type === 'standard' && <Standard {...this.getDeckCoordinates(k)} value={game.cards.opponent[k].value} />}
								{game.cards.opponent[k].type === 'add' && <Add {...this.getDeckCoordinates(k)} value={game.cards.opponent[k].value} />}
								{game.cards.opponent[k].type === 'subtract' && <Subtract {...this.getDeckCoordinates(k)} value={game.cards.opponent[k].value} />}
								{game.cards.opponent[k].type === 'flip' && <Flip {...this.getDeckCoordinates(k)} value={game.cards.opponent[k].value} />}
								{game.cards.opponent[k].type === 'special' && <Special {...this.getDeckCoordinates(k)} value={game.cards.opponent[k].value} card={game.cards.opponent[k]} />}
							</React.Fragment>}
							{!!((game.p2_stand && game.p1_id === game.user_id) || (game.p1_stand && game.p2_id === game.user_id)) && <rect {...this.getDeckCoordinates(k)} width="83" height="109" rx="5" fill="#181818" fillOpacity="0.5" />}
						</React.Fragment>
					))}
					</g>
					<g id="player-hand" transform="translate(33.5, 547)" filter="url(#inner-shadow-board-bottom)" className={canPlayCard ? 'active' : 'disabled'}>
					{[...Array(4).keys()].map((c, k) => (
						<React.Fragment key={k}>
							<rect {...this.getHandCoordinates(k)} width="83" height="109" rx="5" fill="#181818" />
							{!!game.hand.you[k] && <React.Fragment>
								{game.hand.you[k].type === 'add' && <Add {...this.getHandCoordinates(k)} value={game.hand.you[k].value} card={game.hand.you[k]} draggable={canPlayCard} onMouseDown={this.handleMouseDown} />}
								{game.hand.you[k].type === 'subtract' && <Subtract {...this.getHandCoordinates(k)} value={game.hand.you[k].value} card={game.hand.you[k]} draggable={canPlayCard} onMouseDown={this.handleMouseDown} />}
								{game.hand.you[k].type === 'flip' && <Flip {...this.getHandCoordinates(k)} value={game.hand.you[k].value} card={game.hand.you[k]} draggable={canPlayCard} onMouseDown={this.handleMouseDown} />}
								{game.hand.you[k].type === 'special' && <Special {...this.getHandCoordinates(k)} value={game.hand.you[k].value} card={game.hand.you[k]} draggable={canPlayCard} onMouseDown={this.handleMouseDown} />}
							</React.Fragment>}
							{!!((game.p1_stand && game.p1_id === game.user_id) || (game.p2_stand && game.p2_id === game.user_id)) && <rect {...this.getHandCoordinates(k)} width="83" height="109" rx="5" fill="#181818" fillOpacity="0.5" />}
							<g {...this.getHandActionCoordinates(k)}>
								<rect width="83" height="30" rx="5" fill="#181818" />
								{activeTurn && canPlayCard && <React.Fragment>
									{(game.hand.you[k].type === 'flip' || game.hand.you[k].card_id === 29 || game.hand.you[k].card_id === 33) && <Rotate fill="#C9B84E" x="7.75" y="0" width="30" height="30" className="toggle" onClick={() => this.handleAction('flip-card', {id: game.hand.you[k].id})} />}
									{game.hand.you[k].type === 'special' && game.hand.you[k].card_id === 33 && <Rotate fill="#059B2B" x="45.25" y="0" width="30" height="30" className="toggle" onClick={() => this.handleAction('change-card', {id: game.hand.you[k].id})} />}
								</React.Fragment>}
							</g>
						</React.Fragment>
					))}
					</g>
					<g transform="translate(494.5, 547)" filter="url(#inner-shadow-board-bottom)">
					{[...Array(4).keys()].map((c, k) => (
						<React.Fragment key={k}>
							<rect {...this.getHandCoordinates(k)} width="83" height="109" rx="5" fill="#181818" />
							{game.hand.opponent[k].played === 0 && <Reverse {...this.getHandCoordinates(k)} />}
							{!!((game.p2_stand && game.p1_id === game.user_id) || (game.p1_stand && game.p2_id === game.user_id)) && <rect {...this.getHandCoordinates(k)} width="83" height="110" rx="5" fill="#181818" fillOpacity="0.5" />}
						</React.Fragment>
					))}
					</g>
					<rect x="33.5" y="696" width="362" height="65" rx="5" fill="#181818" filter="url(#inner-shadow-board-bottom)" />
					{activeTurn && canPlayCard && currentHandTypes.flip && <g transform="translate(43.5, 698)">
						<Rotate fill="#C9B84E" x="0" y="0" width="30" height="30" />
						<text x="40" y="14" className="label-text light description">Flip hand card</text>
					</g>}
					{activeTurn && canPlayCard && currentHandTypes.change && <g transform="translate(43.5, 729)">
						<Rotate fill="#059B2B" x="0" y="0" width="30" height="30" />
						<text x="40" y="14" className="label-text light description">Change card value</text>
					</g>}
					<g id="end-turn" className={activeTurn && !pendingAction ? 'active' : 'disabled'}>
						<rect x="494.5" y="696" width="176" height="30" rx="5" fill="#181818" className="label-container" filter="url(#inner-shadow-board-bottom)" onClick={() => this.handleAction('end-turn')} />
						<text x="582.5" y="710" className="label-text">End Turn</text>
					</g>
					<g id="stand" className={activeTurn && !pendingAction ? 'active' : 'disabled'}>
						<rect x="680.5" y="696" width="176" height="30" rx="5" fill="#181818" className="label-container" filter="url(#inner-shadow-board-bottom)" onClick={() => this.handleAction('stand')} />
						<text x="768.5" y="710" className="label-text">Stand</text>
					</g>
					<g id="forfeit" className={game.status === 'active' && !pendingAction ? 'active' : 'disabled'}>
						<rect x="494.5" y="731" width="362" height="30" rx="5" fill="#181818" className="label-container" filter="url(#inner-shadow-board-bottom)" onClick={() => this.confirmForfeit(true)} />
						<text x="675.5" y="745" className="label-text">Forfeit Game</text>
					</g>
					{game.phase === 'interim' && !confirmed && !confirmForfeit && <React.Fragment>
						<rect x="295" y="347" width="300" height="100" rx="5" opacity="0.5" stroke="#555" />
						{((game.setStatus === 'p1' && game.p1_id === game.user_id) || (game.setStatus === 'p2' && game.p2_id === game.user_id)) && <text x="445" y="372" className="label-text light">You win the set</text>}
						{((game.setStatus === 'p1' && game.p2_id === game.user_id) || (game.setStatus === 'p2' && game.p1_id === game.user_id)) && <text x="445" y="372" className="label-text light">You lose the set</text>}
						{game.setStatus === 'draw' && <text x="445" y="372" className="label-text light">The set is tied</text>}
						<g id="modal" className="active">
							<rect x="357" y="397" width="176" height="30" rx="5" fill="#181818" className="label-container" filter="url(#inner-shadow-board-bottom)" onClick={() => this.handleAction('continue')} />
							<text x="445" y="411" className="label-text">Ok</text>
						</g>
					</React.Fragment>}
					{game.status === 'complete' && !confirmForfeit && <React.Fragment>
						<rect x="270" y="347" width="350" height="100" rx="5" opacity="0.5" stroke="#555" />
						{game.user_id === game.winner_id && game.forfeit && game.winner_id && <text x="445" y="372" className="label-text light">Your opponent has resigned</text>}
						{game.user_id !== game.winner_id && game.forfeit && game.winner_id && <text x="445" y="372" className="label-text light">You have resigned</text>}
						{game.user_id === game.winner_id && !game.forfeit && game.winner_id && <text x="445" y="372" className="label-text light">You are victorious</text>}
						{game.user_id !== game.winner_id && !game.forfeit && game.winner_id && <text x="445" y="372" className="label-text light">You have been defeated</text>}
						{game.user_id && !game.winner_id && <text x="445" y="372" className="label-text light">The game has ended</text>}
						<g id="modal" className="active">
							<rect x="357" y="397" width="176" height="30" rx="5" fill="#181818" className="label-container" filter="url(#inner-shadow-board-bottom)" onClick={() => this.props.onEndGame('complete')} />
							<text x="445" y="411" className="label-text">Ok</text>
						</g>
					</React.Fragment>}
					{confirmForfeit && game.status !== 'complete' && <React.Fragment>
						<rect x="245" y="347" width="400" height="100" rx="5" opacity="0.5" stroke="#555" />
						<text x="445" y="372" className="label-text light">Are you sure you wish to forfeit?</text>
						<g id="modal" className="active">
							<rect x="261" y="397" width="176" height="30" rx="5" fill="#181818" className="label-container" filter="url(#inner-shadow-board-bottom)" onClick={() => this.props.onEndGame('forfeit')} />
							<text x="349" y="411" className="label-text">Yes</text>
							<rect x="453" y="397" width="176" height="30" rx="5" fill="#181818" className="label-container" filter="url(#inner-shadow-board-bottom)" onClick={() => this.confirmForfeit(false)} />
							<text x="541" y="411" className="label-text">No</text>
						</g>
					</React.Fragment>}
				</svg>
				<div id="drag-card" ref={this.drag}>
					{drag.id && <React.Fragment>
						{drag.type === 'add' && <Add x="0" y="0" value={drag.value} />}
						{drag.type === 'subtract' && <Subtract x="0" y="0" value={drag.value} />}
						{drag.type === 'flip' && <Flip x="0" y="0" value={drag.value} />}
						{drag.type === 'special' && <Special x="0" y="0" value={drag.value} card={drag} />}
					</React.Fragment>}
				</div>
			</React.Fragment>
		);
	}	
};

export default Board;
