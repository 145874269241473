import React, { useEffect } from 'react';
import Game from '../services/gameService';
import { ReactComponent as Loading } from '../images/loading.svg';

const Join = ({ history, match }) => {
	useEffect(async () => {
		const id = match.params.id;

		if (! id || id.length !== 6) {
			return history.replace('/game');
		}

		const data = { id };
		const { data: response } = await Game.find(data);

		if (response.game) {
			sessionStorage.setItem('game', JSON.stringify(response.game));

			return history.push('/game/view');
		}

		return history.replace('/game');
	}, []);

	return (
		<div className="waiting-outer">
			<div className="waiting">
				<Loading />
			</div>
		</div>
	);	
}

export default Join;
