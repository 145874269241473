import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import ProtectedRoute from './components/common/protectedRoute';
import Game from './components/game';
import GameForm from './components/forms/game';
import Join from './components/join';
import Logout from './components/logout';
import LoginForm from './components/forms/login';
import RegisterForm from './components/forms/register';
import Rules from './components/rules';

class App extends Component {
    render() {
        return (
            <main className="background">
                <div id="game-container">
                    <Switch>
                        <Route path="/register" component={RegisterForm} />
                        <ProtectedRoute path="/game/view" component={Game} />
                        <ProtectedRoute path="/game" component={GameForm} />
                        <ProtectedRoute path="/rules" component={Rules} />
                        <ProtectedRoute path="/join/:id" component={Join} />
                        <ProtectedRoute path="/logout" component={Logout} />
                        <Route path="/" component={LoginForm} />
                    </Switch>
                </div>
            </main>
        );
    }
}

export default App;
