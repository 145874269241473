import React from 'react';

const Defs = () => {
	return (
		<defs>
			<filter id="inner-shadow">
				<feComponentTransfer in="SourceAlpha">
					<feFuncA type="table" tableValues="1 0" />
				</feComponentTransfer>
				<feGaussianBlur stdDeviation="1" />
				<feOffset dx="1" dy="-1" result="offsetblur" />
				<feFlood floodColor="#606060" result="color" />
				<feComposite in2="offsetblur" operator="in" />
				<feComposite in2="SourceAlpha" operator="in" />
				<feMerge>
					<feMergeNode in="SourceGraphic" />
					<feMergeNode />
				</feMerge>
			</filter>
			<filter id="inner-shadow-panel">
				<feComponentTransfer in="SourceAlpha">
					<feFuncA type="table" tableValues="1 0" />
				</feComponentTransfer>
				<feGaussianBlur stdDeviation="1" />
				<feOffset dx="1" dy="-1" result="offsetblur" />
				<feFlood floodColor="#555" result="color" />
				<feComposite in2="offsetblur" operator="in" />
				<feComposite in2="SourceAlpha" operator="in" />
				<feMerge>
					<feMergeNode in="SourceGraphic" />
					<feMergeNode />
				</feMerge>
			</filter>
			<filter id="inner-shadow-arrow">
				<feComponentTransfer in="SourceAlpha">
					<feFuncA type="table" tableValues="1 0" />
				</feComponentTransfer>
				<feGaussianBlur stdDeviation="1" />
				<feOffset dx="-1" dy="-1" result="offsetblur" />
				<feFlood floodColor="#555" result="color" />
				<feComposite in2="offsetblur" operator="in" />
				<feComposite in2="SourceAlpha" operator="in" />
				<feMerge>
					<feMergeNode in="SourceGraphic" />
					<feMergeNode />
				</feMerge>
			</filter>
			<filter id="inner-shadow-board-bottom">
				<feComponentTransfer in="SourceAlpha">
					<feFuncA type="table" tableValues="1 0" />
				</feComponentTransfer>
				<feGaussianBlur stdDeviation="1" />
				<feOffset dx="0" dy="-1" result="offsetblur" />
				<feFlood floodColor="#555" result="color" />
				<feComposite in2="offsetblur" operator="in" />
				<feComposite in2="SourceAlpha" operator="in" />
				<feMerge>
					<feMergeNode in="SourceGraphic" />
					<feMergeNode />
				</feMerge>
			</filter>
			<filter id="inner-shadow-board-top">
				<feComponentTransfer in="SourceAlpha">
					<feFuncA type="table" tableValues="1 0" />
				</feComponentTransfer>
				<feGaussianBlur stdDeviation="1" />
				<feOffset dx="0" dy="1" result="offsetblur" />
				<feFlood floodColor="#555" result="color" />
				<feComposite in2="offsetblur" operator="in" />
				<feComposite in2="SourceAlpha" operator="in" />
				<feMerge>
					<feMergeNode in="SourceGraphic" />
					<feMergeNode />
				</feMerge>
			</filter>
			<filter id="background">
				<feTurbulence type="fractalNoise" baseFrequency="0.1" numOctaves="10" result="noise" />
				<feDiffuseLighting in="noise" lightingColor="#AD9685" surfaceScale="1" result="diffLight">
					<fePointLight x="41.5" y="54.5" z="100" />
				</feDiffuseLighting>
			</filter>
			<filter id="top-triangle-background">
				<feTurbulence type="fractalNoise" baseFrequency="0.1" numOctaves="10" result="noise" />
				<feDiffuseLighting in="noise" lightingColor="#AD9685" surfaceScale="1" result="diffLight">
					<fePointLight x="41.5" y="24" z="10" />
				</feDiffuseLighting>
			</filter>
			<filter id="bottom-triangle-background">
				<feTurbulence type="fractalNoise" baseFrequency="0.1" numOctaves="10" result="noise" />
				<feDiffuseLighting in="noise" lightingColor="#AD9685" surfaceScale="1" result="diffLight">
					<fePointLight x="41.5" y="66" z="10" />
				</feDiffuseLighting>
			</filter>
			<filter id="reverse-background">
				<feTurbulence type="fractalNoise" baseFrequency="0.1" numOctaves="10" result="noise" />
				<feDiffuseLighting in="noise" lightingColor="#655E54" surfaceScale="1" result="diffLight">
					<fePointLight x="41.5" y="45" z="50" />
				</feDiffuseLighting>
			</filter>
			<filter id="left-shadow">
				<feDropShadow dx="-2" dy="0" stdDeviation="1" floodColor="#6B5A48" />
			</filter>
			<linearGradient id="add-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
				<stop offset="0%" stopColor="#02186B" />
				<stop offset="45%" stopColor="#3157D2" />
				<stop offset="55%" stopColor="#3157D2" />
				<stop offset="100%" stopColor="#02186B" />
			</linearGradient>
			<filter id="add-top-shadow">
				<feDropShadow dx="1" dy="-1" stdDeviation="0.5" floodColor="#02186B" />
			</filter>
			<filter id="add-bottom-shadow">
				<feComponentTransfer in="SourceAlpha">
					<feFuncA type="table" tableValues="1 0" />
				</feComponentTransfer>
				<feGaussianBlur stdDeviation="4" />
				<feOffset dx="3" dy="3" result="offsetblur" />
				<feFlood floodColor="#02186B" result="color" />
				<feComposite in2="offsetblur" operator="in" />
				<feComposite in2="SourceAlpha" operator="in" />
				<feMerge>
					<feMergeNode in="SourceGraphic" />
					<feMergeNode />
				</feMerge>
			</filter>
			<filter id="add-bottom-triangle-shadow">
				<feDropShadow dx="0" dy="1" stdDeviation="0.5" floodColor="#3A4ECB" />
			</filter>
			<linearGradient id="subtract-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
				<stop offset="0%" stopColor="#871009" />
				<stop offset="45%" stopColor="#E5504F" />
				<stop offset="55%" stopColor="#E5504F" />
				<stop offset="100%" stopColor="#871009" />
			</linearGradient>
			<filter id="subtract-top-shadow">
				<feDropShadow dx="1" dy="-1" stdDeviation="0.5" floodColor="#871009" />
			</filter>
			<filter id="subtract-bottom-shadow">
				<feComponentTransfer in="SourceAlpha">
					<feFuncA type="table" tableValues="1 0" />
				</feComponentTransfer>
				<feGaussianBlur stdDeviation="4" />
				<feOffset dx="3" dy="3" result="offsetblur" />
				<feFlood floodColor="#871009" result="color" />
				<feComposite in2="offsetblur" operator="in" />
				<feComposite in2="SourceAlpha" operator="in" />
				<feMerge>
					<feMergeNode in="SourceGraphic" />
					<feMergeNode />
				</feMerge>
			</filter>
			<filter id="subtract-bottom-triangle-shadow">
				<feDropShadow dx="0" dy="1" stdDeviation="0.5" floodColor="#A55C53" />
			</filter>
			<linearGradient id="standard-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
				<stop offset="0%" stopColor="#088627" />
				<stop offset="45%" stopColor="#4EE467" />
				<stop offset="55%" stopColor="#4EE467" />
				<stop offset="100%" stopColor="#088627" />
			</linearGradient>
			<filter id="standard-top-shadow">
				<feDropShadow dx="1" dy="-1" stdDeviation="0.5" floodColor="#088627" />
			</filter>
			<filter id="standard-bottom-shadow">
				<feComponentTransfer in="SourceAlpha">
					<feFuncA type="table" tableValues="1 0" />
				</feComponentTransfer>
				<feGaussianBlur stdDeviation="4" />
				<feOffset dx="3" dy="3" result="offsetblur" />
				<feFlood floodColor="#088627" result="color" />
				<feComposite in2="offsetblur" operator="in" />
				<feComposite in2="SourceAlpha" operator="in" />
				<feMerge>
					<feMergeNode in="SourceGraphic" />
					<feMergeNode />
				</feMerge>
			</filter>
			<filter id="standard-bottom-triangle-shadow">
				<feDropShadow dx="0" dy="1" stdDeviation="0.5" floodColor="#33812D" />
			</filter>
			<linearGradient id="special-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
				<stop offset="0%" stopColor="#8A7810" />
				<stop offset="45%" stopColor="#E3CB51" />
				<stop offset="55%" stopColor="#E3CB51" />
				<stop offset="100%" stopColor="#8A7810" />
			</linearGradient>
			<filter id="special-top-shadow">
				<feDropShadow dx="1" dy="-1" stdDeviation="0.5" floodColor="#8A7810" />
			</filter>
			<filter id="special-bottom-shadow">
				<feComponentTransfer in="SourceAlpha">
					<feFuncA type="table" tableValues="1 0" />
				</feComponentTransfer>
				<feGaussianBlur stdDeviation="4" />
				<feOffset dx="3" dy="3" result="offsetblur" />
				<feFlood floodColor="#8A7810" result="color" />
				<feComposite in2="offsetblur" operator="in" />
				<feComposite in2="SourceAlpha" operator="in" />
				<feMerge>
					<feMergeNode in="SourceGraphic" />
					<feMergeNode />
				</feMerge>
			</filter>
			<filter id="special-bottom-triangle-shadow">
				<feDropShadow dx="0" dy="1" stdDeviation="0.5" floodColor="#BCA455" />
			</filter>
			<linearGradient id="flip-gradient-add" x1="0%" y1="0%" x2="100%" y2="0%">
				<stop offset="0%" stopColor="#3157D2" />
				<stop offset="10%" stopColor="#3157D2" />
				<stop offset="100%" stopColor="#02186B" />
			</linearGradient>
			<linearGradient id="flip-gradient-subtract" x1="0%" y1="0%" x2="100%" y2="0%">
				<stop offset="0%" stopColor="#871009" />
				<stop offset="90%" stopColor="#E5504F" />
				<stop offset="100%" stopColor="#E5504F" />
			</linearGradient>
			<linearGradient id="turn-gradient-left" x1="100%" y1="0%" x2="0%" y2="0%">
				<stop offset="0%" stopColor="#330709" />
				<stop offset="100%" stopColor="#C70200" />
			</linearGradient>
			<linearGradient id="turn-gradient-right" x1="100%" y1="0%" x2="0%" y2="0%">
				<stop offset="0%" stopColor="#C70200" />
				<stop offset="100%" stopColor="#330709" />
			</linearGradient>
			<linearGradient id="set-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
				<stop offset="0%" stopColor="#330709" />
				<stop offset="100%" stopColor="#C70200" />
			</linearGradient>
			<filter id="reverse-top-shadow">
				<feDropShadow dx="1" dy="-1" stdDeviation="0.5" floodColor="#504740" />
			</filter>
			<radialGradient id="reverse-circle-gradient" cx="75%" cy="25%">
				<stop offset="0%" stopColor="#888075" />
				<stop offset="100%" stopColor="#3C2F27" />
			</radialGradient>
			<filter id="glow" x0="-50%" y0="-50%" width="200%" height="200%">
				<feGaussianBlur in="SourceAlpha" stdDeviation="3" result="blur"></feGaussianBlur>
				<feOffset dy="10" dx="10"></feOffset>
				<feComposite in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowDiff"></feComposite>
				<feFlood floodColor="#330000" floodOpacity="0.75"></feFlood>
				<feComposite in2="shadowDiff" operator="in"></feComposite>
				<feComposite in2="SourceGraphic" operator="over" result="filterOne"></feComposite>
				<feGaussianBlur in="filterOne" stdDeviation="3" result="blur2"></feGaussianBlur>
				<feOffset dy="-10" dx="-10"></feOffset>
				<feComposite in2="filterOne" operator="arithmetic" k2="-1" k3="1" result="shadowDiff"></feComposite>
				<feFlood floodColor="#330000" floodOpacity="0.75"></feFlood>
				<feComposite in2="shadowDiff" operator="in"></feComposite>
				<feComposite in2="filterOne" operator="over"></feComposite>
			</filter>
			<filter id="glow-score" x0="-50%" y0="-50%" width="200%" height="200%">
				<feGaussianBlur in="SourceAlpha" stdDeviation="3" result="blur"></feGaussianBlur>
				<feOffset dy="1" dx="1"></feOffset>
				<feComposite in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowDiff"></feComposite>
				<feFlood floodColor="#800000" floodOpacity="0.75"></feFlood>
				<feComposite in2="shadowDiff" operator="in"></feComposite>
				<feComposite in2="SourceGraphic" operator="over" result="filterOne"></feComposite>
				<feGaussianBlur in="filterOne" stdDeviation="3" result="blur2"></feGaussianBlur>
				<feOffset dy="-1" dx="-1"></feOffset>
				<feComposite in2="filterOne" operator="arithmetic" k2="-1" k3="1" result="shadowDiff"></feComposite>
				<feFlood floodColor="#800000" floodOpacity="0.75"></feFlood>
				<feComposite in2="shadowDiff" operator="in"></feComposite>
				<feComposite in2="filterOne" operator="over"></feComposite>
			</filter>
			<mask id="score-mask">
				<rect x="0" y="0" width="890" height="794" fill="white" />
				<circle cx="445" cy="100" r="100" />
				<circle cx="445" cy="100" r="173" fill="transparent" stroke="black" strokeWidth="10" />
				<circle cx="445" cy="100" r="332.5" fill="transparent" stroke="black" strokeWidth="10" />
				<circle cx="445" cy="100" r="252.5" fill="transparent" stroke="black" strokeWidth="10" />
			</mask>
			<mask id="name-mask">
				<rect x="0" y="0" width="890" height="794" fill="white" />
				<circle cx="445" cy="100" r="173" fill="transparent" stroke="black" strokeWidth="10" />
				<circle cx="445" cy="100" r="413" fill="transparent" stroke="black" strokeWidth="10" />
			</mask>
			<mask id="turn-mask">
				<rect x="0" y="0" width="890" height="794" fill="white" />
				<path d="M 430 10 h 30 v 180 h -30 v -180" />
				<circle cx="445" cy="100" r="60" />
			</mask>
		</defs>
	);
};

export default Defs;
