import React from 'react';

const Reverse = ({ x, y }) => {
	return (
		<svg viewBox="0 0 83 109" xmlns="http://www.w3.org/2000/svg" width="83" height="109" className="pazaak-card" x={x} y={y}>
			<g clipPath="url(#card)">
				<rect x="9" y="89" width="65" height="24" rx="4" fill="#7F7367" filter="url(#inner-shadow-board-bottom)" />
				<path d="M 0 0 h 83 v 109 h -9 v -16 a 4, 4 0 0 0 -4, -4 h -20 a 8, 8 0 0 1 -16, 0 h -21 a 4, 4 0 0 0 -4, 4 v 16 h -9 v -109" fill="#544D47" />
				<path d="M 13 10 h 57 a 4, 4 0 0 1 4, 4 v 59 a 4, 4 0 0 1 -4, 4 h -57 a 4, 4 0 0 1 -4, -4 v -59 a 4, 4 0 0 1 4, -4" fill="#7F7367"  filter="url(#inner-shadow-board-bottom)" />
				<path d="M 15 32 h 15 l 11.5 -14 l 11.5 14 h 15 a 4, 4 0 0 1 4, 4 v 18 a 4, 4, 0 0 1 -4, 4 h -15 l -11.5 14 l -11.5 -14 h -15 a 4, 4 0 0 1 -4, -4 v -18 a 4, 4 0 0 1 4, -4" fill="#655E54" filter="url(#reverse-background)" clipPath="url(#reverse-shape)" />
				<circle cx="69.5" cy="13.5" r="9.25" fill="url(#reverse-circle-gradient)" />
			</g>
		</svg>
	);
};

export default Reverse;
