import React, { useCallback, useState } from 'react';
import { ReactComponent as Loading } from '../images/loading.svg';

const WaitingForAdditionalPlayer = ({ game, onCancelGame }) => {
	const [linkCopied, setCopied] = useState(false);

	const copyLink = useCallback(link => {
		if (navigator.clipboard) {
			navigator.clipboard.writeText(link);
		} else {
			document.querySelector('#link-copy').select();
			document.execCommand('copy');
		}

		setCopied(true);

		window.setTimeout(() => setCopied(false), 3000);
	});

	const link = `${window.location.origin}/join/${game.id}`;

	return (
		<div className="waiting-outer">
			<div className="waiting">
				<Loading />
				<p>Waiting for opponent...</p>
				{game.private === 1 && <p>An invitation link has been generated:</p>}
				{game.private === 1 && linkCopied && <p className="copy-link">Link Copied!</p>}
				{game.private === 1 && !linkCopied && <p className="copy-link" onClick={() => copyLink(link)}>{link}</p>}
				<button className="btn cancel" onClick={onCancelGame}>Cancel</button>
				<input type="text" id="link-copy" readOnly value={ link } />
			</div>
		</div>
	);	
};

export default WaitingForAdditionalPlayer;
