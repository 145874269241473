import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Board from './board';
import DeckSelection from './deckSelection';
import WaitingForAdditionalPlayer from './waitingForAdditionalPlayer';
import Socket from '../services/socketService';
import { ReactComponent as Loading } from '../images/loading.svg';

class Game extends Component {
	state = {
		connected: false,
		reconnecting: false,
		reconnectTime: 0,
		game: {}
	};

	reconnect = null;

	componentDidMount() {
		this.initSocket();
	};

	componentWillUnmount() {
		clearInterval(this.reconnect);

		Socket.disconnect(1000);
	};

	initSocket() {
		Socket.connect({
			onopen: () => {
				clearInterval(this.reconnect);
			},
			onmessage: (data) => {
				if (data.game === false) {
					sessionStorage.removeItem('game');

					this.props.history.replace('/game');
				}

				if ((data.type === 'load' || data.type === 'update') && data.game) {
					this.setState({ game: data.game, connected: true, reconnecting: false });

					if (data.game.status === 'complete') {
						sessionStorage.removeItem('game');
					}
				}
			},
			onclose: () => {
				clearInterval(this.reconnect);

				this.reconnect = setInterval(() => {
					const reconnectTime = this.state.reconnectTime - 1;

					this.setState({ reconnectTime });

					if (reconnectTime === 0) {
						this.initSocket();

						clearInterval(this.reconnect);
					}
				}, 1000);

				this.setState({ connected: false, reconnecting: true, reconnectTime: 5 });
			}
		});
	};

	handleDeckConfirm = ids => {
		Socket.send({
			action: 'confirm-deck',
			data: { ids }
		});
	};

	handleAction = (action, data) => {
		Socket.send({
			action,
			data
		});
	};

	handleEndGame = action => {
		if (action === 'forfeit') {
			Socket.send({
				action: 'forfeit',
				data: {}
			});
		} else {
			this.props.history.replace('/game');
		}
	};

	handleCancelGame = () => {
		sessionStorage.removeItem('game');

		this.props.history.replace('/game');
	};

	render() {
		const { connected, game } = this.state;

		if (! sessionStorage.getItem('game')) {
			return <Redirect to="/game" />;
		}

		if (! connected) {
			return (
				<div className="waiting-outer">
					<div className="waiting">
						<Loading />
					</div>
				</div>
			);
		}

		if (game.status === 'open') {
			return <WaitingForAdditionalPlayer game={game} onCancelGame={this.handleCancelGame} />;		
		}

		if (game.status === 'active' && game.phase === 'selection') {
			return <DeckSelection game={game} onConfirm={this.handleDeckConfirm} />;
		}

		if (game.status === 'active' || game.status === 'complete') {
			return <Board game={game} onAction={this.handleAction} onEndGame={this.handleEndGame} />;
		}

		return null;
	};	
}

export default Game;
