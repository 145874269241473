import React, { Component } from 'react';
import Add from './svg/add';
import ClipPath from './svg/clipPath';
import Defs from './svg/defs';
import Flip from './svg/flip';
import Special from './svg/special';
import Standard from './svg/standard';
import Subtract from './svg/subtract';

class DeckSelection extends Component {
	state = {
		selected: false
	};

	static getDerivedStateFromProps(props, state) {
		if (!state.selected && props.game.selected) {
			return { selected: props.game.selected };
		} else if (!state.selected) {
			return { selected: [] };
		}

		return null;
	};

	getCoordinates(type, key) {
		if (type === 'add') {
			return {x: 20 + key * 103, y: 60};
		} else if (type === 'subtract') {
			return {x: 20 + key * 103, y: 179};
		} else if (type === 'flip') {
			return {x: 20 + key * 103, y: 298};
		} else if (type === 'special') {
			return {x: 20 + key * 103, y: 417};
		} else if (type === 'selected') {
			return {x: 685 + (key % 2 === 1 ? 103 : 0), y: 60 + Math.floor(key / 2) * 119};
		}
	};

	selectCard(card) {
		const selected = [...this.state.selected];

		if (selected.length === 10 || this.props.game.locked) {
			return;
		}

		selected.push(card);

		this.setState({ selected });
	};

	unselectCard(index) {
		const selected = [...this.state.selected];

		if (this.props.game.locked) {
			return;
		}

		selected.splice(index, 1);

		this.setState({ selected });
	};

	confirmSelection() {
		const selected = this.state.selected;

		if (selected.length < 10 || this.props.game.locked) {
			return;
		}

		const ids = selected.reduce((ids, card) => {
			ids.push(parseInt(card.id));

			return ids;
		}, []);

		return this.props.onConfirm(ids);
	};

	render() {
		const selected = this.state.selected;
		const game = this.props.game;
		const activeSpecial = selected.reduce((special, card) => {
			if (card.type === 'special') {
				special[card.id] = true;
			}

			return special;
		}, {});

		return (
			<svg id="deck-container" viewBox="0 0 890 704">
				<Defs />
				<ClipPath />
				<rect x="20" y="20" width="290" height="30" rx="5" className="label-container" filter="url(#inner-shadow)" />
				<text x="165" y="34" className="label-text">Available Cards</text>
				<rect x="330" y="20" width="290" height="30" rx="5" className="label-container" filter="url(#inner-shadow)" />
				<text x="475" y="34" className="label-text middle">Choose Sidedeck</text>
				<rect x="640" y="20" width="231" height="30" rx="5" className="label-container" filter="url(#inner-shadow)" />
				<text x="755.5" y="34" className="label-text">Chosen Cards</text>
				{game.cards.add.map((c, k) => (
					<Add key={c.id} {...this.getCoordinates('add', k)} value={c.value} onClick={() => this.selectCard(c)} />
				))}
				{game.cards.subtract.map((c, k) => (
					<Subtract key={c.id} {...this.getCoordinates('subtract', k)} value={c.value} onClick={() => this.selectCard(c)} />
				))}
				{game.cards.flip.map((c, k) => (
					<Flip key={c.id} {...this.getCoordinates('flip', k)} value={c.value} selection="true" onClick={() => this.selectCard(c)} />
				))}
				{[...Array(6).keys()].map((c, k) => (
					<rect key={k} {...this.getCoordinates('special', k)} width="83" height="109" rx="5" fill="#181818" filter="url(#inner-shadow-board-bottom)" />
				))}
				{game.cards.special && <React.Fragment>
					{game.cards.special.map((c, k) => (
						<React.Fragment key={c.id}>
							{!!activeSpecial[c.id] &&< Special key={c.id} {...this.getCoordinates('special', k)} value={c.value} className="selected" />} 
							{!activeSpecial[c.id] && <Special key={c.id} {...this.getCoordinates('special', k)} value={c.value} onClick={() => this.selectCard(c)} />}
						</React.Fragment>
					))}
				</React.Fragment>}
				<path d="M 645 60 h 15 a 5, 5 0 0 1 5, 5 v 575 a 5, 5 0 0 1 -5, 5 h -15 a 5, 5 0 0 1 -5, -5 v -109 a 5, 5, 0 0 0 -5 -5 h -95 a 5, 5 0 0 1 -5, -5 v -99 a 5, 5 0 0 1 5, -5 h 95 a 5, 5 0 0 0 5, -5 v -347 a 5, 5 0 0 1 5, -5 z" fill="#181818" filter="url(#inner-shadow-panel)" />
				<path d="M 545 451.5 h 46.5 v -25 l 46.5 44.5 l -46.5 44.5 v -25 h -46.5 z" fill="#383838" filter="url(#inner-shadow-arrow)" />
				<g id="card-display">
					<path d="M 25 536 h 382 a 5, 5 0 0 1 5, 5 v 135 a 5, 5 0 0 1 -5, 5 h -382 a 5, 5 0 0 1 -5, -5 v -135 a 5, 5 0 0 1 5, -5" fill="#181818" filter="url(#inner-shadow-panel)" />
					<Add x="25" y="541" />
					<Subtract x="99.75" y="554" />
					<Standard x="324" y="541" />
					<Flip x="249.25" y="554" value="0" />
					<Special x="174.5" y="567" />
					<path id="card-display-overlay" d="M 25 536 h 382 a 5, 5 0 0 1 5, 5 v 135 a 5, 5 0 0 1 -5, 5 h -382 a 5, 5 0 0 1 -5, -5 v -135 a 5, 5 0 0 1 5, -5" fill="#181818" />
				</g>
				<g id="card-confirm" className={selected.length === 10 && ! game.locked ? 'active' : 'disabled'}>
					<rect x="640" y="654" width="231" height="30" rx="5" className="label-container" onClick={() => this.confirmSelection()} />
					<text x="755.5" y="669" className="label-text">Confirm</text>
				</g>
				{game.phase_time >= 0 && <text className="game-timer-text" x="525" y="608.5">{game.phase_time}</text>}
				{[...Array(10).keys()].map((c, k) => (
					<rect key={k} {...this.getCoordinates('selected', k)} width="83" height="109" rx="5" fill="#181818" filter="url(#inner-shadow-board-bottom)" />
				))}
				{selected.map((c, k) => (
					<React.Fragment key={k}>
						{c.type === 'add' && <Add key={c.id} {...this.getCoordinates('selected', k)} value={c.value} onClick={() => this.unselectCard(k)} />}
						{c.type === 'subtract' && <Subtract key={c.id} {...this.getCoordinates('selected', k)} value={c.value} onClick={() => this.unselectCard(k)} />}
						{c.type === 'flip' && <Flip key={c.id} {...this.getCoordinates('selected', k)} value={c.value} selection="true" onClick={() => this.unselectCard(k)} />}
						{c.type === 'special' && <Special key={c.id} {...this.getCoordinates('selected', k)} value={c.value} onClick={() => this.unselectCard(k)} />}
					</React.Fragment>
				))}
			</svg>
		);
	};
}

export default DeckSelection;
