import React from 'react';

const Standard = ({ x, y, value }) => {
	return (
		<svg viewBox="0 0 83 109" xmlns="http://www.w3.org/2000/svg" width="83" height="109" className="pazaak-card" x={x} y={y}>
			<g clipPath="url(#card)">
				<rect x="0" y="0" width="83" height="109" fill="white" filter="url(#background)" />
				<path d="M 13 10 h 48.5 a 9.5, 9.5 0 1 1 12.5, 12.5 v 50.5 a 4, 4 0 0 1 -4, 4 h -57 a 4, 4 0 0 1 -4, -4 v -59 a 4, 4 0 0 1 4, -4" fill="url(#standard-gradient)" filter="url(#standard-bottom-shadow)" />
				<rect x="9" y="30" width="65" height="30" fill="#99887C" filter="url(#left-shadow)" />
				<polygon points="31 31, 41.5 18, 52 31, 31 31" fill="none" filter="url(#top-triangle-background)" clipPath="url(#triangle-up)" />
				<polygon points="32 60, 41.5 72, 51 60, 32 60" fill="#a7917d" filter="url(#standard-bottom-triangle-shadow)" />
				<polygon points="32 60, 41.5 72, 51 60, 32 60" fill="none" filter="url(#bottom-triangle-background)" clipPath="url(#triangle-down)" />
				<rect x="11" y="32" width="61" height="26" fill="#010101" strokeWidth="1" stroke="#51443D" />
				{value && <text x="43.5" y="44">{value}</text>}
				<rect x="9" y="89" width="64" height="24" rx="4" fill="url(#standard-gradient)" filter="url(#standard-top-shadow)" />
			</g>
		</svg>
	);
};

export default Standard;
