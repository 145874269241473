import React from 'react';
import { Link } from 'react-router-dom';

const Rules = props => {
	return (
		<div className="rules-outer">
			<h2>Pazaak Rules</h2>
			<p>The object of the game is to have your face up cards total higher than your opponent's hand without exceeding a total of 20. If a player's total is greater than 20 at the end of a turn (a 'bust'), the opponent wins the set. A player must win 3 sets to win the match and collect the wager.</p>
			<ol>
				<li>Each player draws 4 random cards from their side deck to form their HAND. These cards are available to the player for all the sets of the match. Each hand card can only be played once per match.</li>
				<li>The first player draws a card from the main deck and plays it face up to begin their turn.</li>
				<li>After drawing a card, a player has the option of playing one of the cards from their hand. Playing a card from the hand is optional.</li>
				<li>Only one hand card can be played each turn. REMEMBER: A player's 4 card hand must last through all sets of the match.</li>
				<li>The player now has the option to STAND or to END TURN. A player who stands cannot draw again or play any more cards from their hand during the set. When one player stands, the opponent can continue to take turns until they either stand or bust.</li>
				<li>If a player chooses to end turn they will automatically draw another card at the beginning of their next turn. After one player chooses to stand or end turn, the opponent draws a card to begin their next turn.</li>
				<li>Players continue to alternate turns until both players choose to stand, or until one player ends a turn with a total greater than 20 (a 'bust'). REMEMBER: You only lose if you are over 20 at the end of your turn. A player can draw a card from the main deck that puts them over 20, then play a negative card from the 'hand' that brings the total back under 20.</li>
				<li>In the case of a tie, another set must be played. No new cards from the side deck are drawn.</li>
				<li>A player can never have more than nine cards in play during one set. (This includes cards from the hand as well as cards dealt from the deck.) Once you have nine cards in play you automatically stand.</li>
				<li>A +/- card played from the side deck must be declared as a positive or negative when played. Once played it cannot be changed.</li>
				<li>Gold cards have special rules associated with them. With some you can alter their number before you play them. Others can break a tie, change cards in play, or double the value of the last card played.</li>
				<li>The first player to win 3 sets wins the match and the wager.</li>
			</ol>
			<Link to="/">Back</Link>
		</div>
	);
};

export default Rules;
