import React from 'react';

const Flip = ({ x, y, selection, value, card, draggable, onClick, onMouseDown }) => {
	const props = {	x,	y, selection, onClick };

	if (onMouseDown && draggable) {
		props.onMouseDown = event => onMouseDown(event, card);
	}

	return (
		<svg viewBox="0 0 83 109" xmlns="http://www.w3.org/2000/svg" width="83" height="109" className="pazaak-card" {...props}>
			<g clipPath="url(#card)">
				<rect x="0" y="0" width="83" height="109" fill="white" filter="url(#background)" className="background" />
				{value >= 0 && <path d="M 13 10 h 48.5 a 9.5, 9.5 0 1 1 12.5, 12.5 v 25.5 h -65 v -34 a 4, 4 0 0 1 4, -4" fill="url(#add-gradient)" filter="url(#add-bottom-shadow)" />}
				{value >= 0 && <path d="M 9 48 h 65 v 25 a 4, 4 0 0 1 -4, 4 h -57 a 4, 4 0 0 1 -4, -4 z" fill="url(#subtract-gradient" filter="url(#subtract-bottom-shadow)" />}
				{value < 0 && <path d="M 13 10 h 48.5 a 9.5, 9.5 0 1 1 12.5, 12.5 v 25.5 h -65 v -34 a 4, 4 0 0 1 4, -4" fill="url(#subtract-gradient)" filter="url(#subtract-bottom-shadow)" />}
				{value < 0 && <path d="M 9 48 h 65 v 25 a 4, 4 0 0 1 -4, 4 h -57 a 4, 4 0 0 1 -4, -4 z" fill="url(#add-gradient" filter="url(#add-bottom-shadow)" />}
				<rect x="9" y="30" width="66" height="30" fill="#99887C" filter="url(#left-shadow)" />
				<polygon points="31 31, 41.5 18, 52 31, 31 31" fill="none" filter="url(#top-triangle-background)" clipPath="url(#triangle-up)" />
				{value >= 0 && <polygon points="32 60, 41.5 72, 51 60, 32 60" fill="#a7917d" filter="url(#subtract-bottom-triangle-shadow)" />}
				{value < 0 && <polygon points="32 60, 41.5 72, 51 60, 32 60" fill="#a7917d" filter="url(#add-bottom-triangle-shadow)" />}
				<polygon points="32 60, 41.5 72, 51 60, 32 60" fill="none" filter="url(#bottom-triangle-background)" clipPath="url(#triangle-down)" />
				<rect x="11" y="32" width="61" height="26" fill="#010101" strokeWidth="1" stroke="#51443D" />
				{value >= 0 && <path d="M 18 69.75 h 12.5 v -2.5 h -12.5 v 2.5" fill="#1A0707" />}
				{value >= 0 && <path d="M 52 69.75 h 12.5 v -2.5 h -12.5 v 2.5" fill="#1A0707" />}
				{value < 0 && <path d="M 18 67.25 h 5 v -5 h 2.5 v 5 h 5 v 2.5 h -5 v 5 h -2.5 v -5 h -5 v -2.5" fill="#1A0707" />}
				{value < 0 && <path d="M 52 67.25 h 5 v -5 h 2.5 v 5 h 5 v 2.5 h -5 v 5 h -2.5 v -5 h -5 v -2.5" fill="#1A0707" />}
				{value > 0 && !selection && <text x="43.5" y="44">+{value}</text>}
				{value > 0 && selection && <text x="43.5" y="44">±{value}</text>}
				{value < 0 && <text x="43.5" y="44">{value}</text>}
				{value >= 0 && <path d="M 63.375 12.375 h 5.5 v -5.5 h 2.75 v 5.5 h 5.5 v 2.75 h -5.5 v 5.5 h -2.75 v -5.5 h -5.5 v -2.75" fill="#FAF7F7" />}
				{value < 0 && <path d="M 63.375 12.375 h 13.75 v 2.75 h -13.75 v -2.75" fill="#FAF7F7" />}
				<path d="M 13 89 h 28.5 v 24 h -32.5 v -20 a 4, 4 0 0 1 4, -4" fill="url(#flip-gradient-subtract)" filter="url(#subtract-top-shadow)" />
				<path d="M 41.5 89 h 27.5 a 4, 4 0 0 1 4, 4 v 24 h -32.5 z" fill="url(#flip-gradient-add)" filter="url(#add-top-shadow)" />
				<path d="M 18 100 h 12.5 v -2.5 h -12.5 v 2.5" fill="#1A0707" />
				<path d="M 52 97.5 h 5 v -5 h 2.5 v 5 h 5 v 2.5 h -5 v 5 h -2.5 v -5 h -5 v -2.5" fill="#1A0707" />
			</g>
		</svg>
	);
};

export default Flip;
