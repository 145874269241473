import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import Joi from 'joi-browser';
import Form from '../common/form';
import Auth from '../../services/authService';
import Game from '../../services/gameService';

class LoginForm extends Form {
	state = {
		data: {
			username: '',
			password: ''
		},
		errors: {}
	};

	schema = {
		username: Joi.string().required().label('Username'),
		password: Joi.string().required().label('Password')
	};

	doSubmit = async () => {
		try {
			const response = await Auth.login(this.state.data);

			if (response !== false) {
				const { data: game} = await Game.get('me');
				const { state } = this.props.location;

				if (game && Object.keys(game).length) {
					sessionStorage.setItem('game', JSON.stringify(game));
				}

				window.location = state ? state.from.pathname : '/game';
			}
		} catch (e) {
			if (e.response && e.response.status === 400) {
				const errors = { ...this.state.errors };

				errors.username = e.response.data;

				this.setState({ errors });
			}
		}
	};

	render() {
		if (Auth.getUser()) {
			return <Redirect to="/game" />;
		}

		return (
			<div className="game-form">
				<h2>Pazaak</h2>
				<form onSubmit={this.handleSubmit}>
					{this.renderInput('username', 'Username')}
					{this.renderInput('password', 'Password', 'password')}
					{this.renderButton('Login')}
				</form>
				<Link to="/register">Register</Link>
			</div>
		);
	}
}

export default LoginForm;
