import React from 'react';

const ClipPath = () => {
	return (
		<React.Fragment>
			<clipPath id="card">
				<rect x="0" y="0" width="83" height="109" fill="none" rx="5" />
			</clipPath>
			<clipPath id="triangle-up">
				<polygon points="31 31, 41.5 18, 52 31, 31 31" fill="none" />
			</clipPath>
			<clipPath id="triangle-down">
				<polygon points="32 60, 41.5 72, 51 60, 32 60" fill="none" />
			</clipPath>
			<clipPath id="reverse-shape">
				<path d="M 15 32 h 15 l 11.5 -14 l 11.5 14 h 15 a 4, 4 0 0 1 4, 4 v 18 a 4, 4, 0 0 1 -4, 4 h -15 l -11.5 14 l -11.5 -14 h -15 a 4, 4 0 0 1 -4, -4 v -18 a 4, 4 0 0 1 4, -4" />
			</clipPath>
			<clipPath id="left-name">
				<rect x="53.5" y="70" width="210" height="30" />
			</clipPath>
			<clipPath id="right-name">
				<rect x="630" y="70" width="210" height="30" />
			</clipPath>
		</React.Fragment>
	);
};

export default ClipPath;
