import http from './httpService';

function get(id) {
    return http.get('/games/' + id);
}

function find(data) {
    return http.post('/games', data);
}

export default {
    get,
    find
};