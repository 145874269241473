import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import Joi from 'joi-browser';
import Form from '../common/form';
import Auth from '../../services/authService';
import User from '../../services/userService';

class RegisterForm extends Form {
	state = {
		data: {
			username: '',
			password: '',
			display_name: ''
		},
		errors: {}
	};

	schema = {
		username: Joi.string().required().max(32).label('Username'),
		password: Joi.string().required().label('Password'),
		display_name: Joi.string().alphanum().required().max(16).label('Display Name')
	};

	doSubmit = async () => {
		try {
			const { data } = await User.save(this.state.data);

			Auth.loginWithToken(data.jwt);

			window.location = '/game';
		} catch (e) {
			if (e.response && e.response.status === 400) {
				const errors = e.response.data;

				this.setState({ errors });
			}
		}
	};

	render() {
		if (Auth.getUser()) {
			return <Redirect to="/game" />;
		}

		return (
			<div className="game-form">
				<h2>Pazaak</h2>
				<form onSubmit={this.handleSubmit}>
					{this.renderInput('username', 'Username')}
					{this.renderInput('password', 'Password', 'password')}
					{this.renderInput('display_name', 'Display Name')}
					{this.renderButton('Register')}
				</form>
				<Link to="/">Login</Link>
			</div>
		);
	}
}

export default RegisterForm;
