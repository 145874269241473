import jwtDecode from 'jwt-decode';
import http from './httpService';
import socket from './socketService';

const tokenKey = 'token';
const gameKey = 'game';

async function login(data) {
    const { data: response } = await http.post('/auth', data);

    if (response.jwt) {
        sessionStorage.setItem(tokenKey, response.jwt);

        http.setToken(getToken());
    } else {
        return false;
    }
};

function loginWithToken(token) {
    sessionStorage.setItem(tokenKey, token);
}

function logout() {
    sessionStorage.removeItem(tokenKey);
    sessionStorage.removeItem(gameKey);
};

function getToken() {
    return sessionStorage.getItem(tokenKey);
}

function getUser() {
    try {
        const jwt = sessionStorage.getItem(tokenKey);
        
        return jwtDecode(jwt);
    } catch (e) {
        return null;
    }
}

http.setToken(getToken());

socket.setToken(getToken());

export default {
    login,
    loginWithToken,
    logout,
    getToken,
    getUser
};