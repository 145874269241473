import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import Joi from 'joi-browser';
import Form from '../common/form';
import GameService from '../../services/gameService';

class GameForm extends Form {
	state = {
		data: {
			practice: false,
			private: false,
			type: 'kotor2'
		},
		errors: {}
	};

	schema = {
		practice: Joi.boolean().required().label('Practice'),
		private: Joi.boolean().required().label('Private'),
		type: Joi.string().required().label('Game Type')
	};

	doSubmit = async () => {
		const data = {...this.state.data};
		const { data: response } = await GameService.find(data);

		sessionStorage.setItem('game', JSON.stringify(response.game));

		return this.props.history.push('/game/view');
	};

	render() {
		const types = [{id: 'kotor1', name: 'Basic'}, {id: 'kotor2', name: 'Telosi'}];

		if (sessionStorage.getItem('game')) {
			return <Redirect to="/game/view" />;
		}

		return (
			<div className="game-form">
				<h2>Pazaak</h2>
				<form onSubmit={this.handleSubmit}>
					{this.renderSelect('type', 'Game Type', types)}
					<div className="form-group">
						<label htmlFor="private">Private Game</label>
						<input id="private" name="private" type="checkbox" value="1" checked={this.state.data.private} onChange={event => this.handleChecked(event)} />
					</div>
					<div className="form-group">
						<label htmlFor="practice">Practice Game</label>
						<input id="practice" name="practice" type="checkbox" value="1" checked={this.state.data.practice} onChange={event => this.handleChecked(event)} />
					</div>	
					{this.renderButton(this.state.data.private || this.state.data.practice ? 'Create Game' : 'Find Game')}
				</form>
				<Link to="/rules">Rules</Link> | <Link to="/logout">Logout</Link>
			</div>
		);
	}
}

export default GameForm;