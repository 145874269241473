const Socket = {
	socket: null,
	token: null,
	callbacks: {onmessage: [], onclose: []},
	setToken(token) {
		this.token = token;
	},
	connect(callbacks) {
		this.socket = new WebSocket(`${process.env.REACT_APP_WS_ENDPOINT}?auth-token=${this.token}`);
		this.socket.onopen = callbacks.onopen || {};

		this.callbacks.onmessage.push(callbacks.onmessage || {});
		this.callbacks.onclose.push(callbacks.onclose || {});

		this.socket.onmessage = event => {
			const data = JSON.parse(event.data);

			this.callbacks.onmessage.forEach(callback => {
				callback.call(this, data);
			});
		}
		this.socket.onclose = event => {
			if (event.code !== 1000) {
				this.callbacks.onclose.forEach(callback => {
					callback.call(this, event);
				});
			}

			this.callbacks = {onmessage: [], onclose: []};
		}
	},
	disconnect(code = 1005) {
		this.socket.close(code);
		this.socket = null;
	},
	send(data) {
		data['auth-token'] = this.token;

		this.socket.send(JSON.stringify(data));
	},
	addCallback(method, callback) {
		this.callbacks[method].push(callback);
	}
}

export default Socket
