import { useEffect } from 'react';
import Auth from '../services/authService';

const Logout = ({ history }) => {
	useEffect(() => {
		Auth.logout();

		return history.replace('/game');
	}, []);

	return null;	
}

export default Logout;
